/*
    FileName: AuthorLinks.js
    Author: Akande Victor
    href: /dash/author
    Description: This file renders the form for a user to update 
    their research links.
*/

import { Alert, FormControl, InputAdornment, InputLabel, MenuItem, Select, Snackbar, TextField } from "@mui/material";
import { useFormik } from "formik"
import { useState } from "react";
import { useCookies } from "react-cookie"
import * as Yup from 'yup';
import { SiGooglescholar, SiPubmed, SiResearchgate, SiScopus } from 'react-icons/si';

const validationSchema = Yup.object({
    google_scholar: Yup
        .string()
        .required("Enter a Google scholar link"),
    research_gate: Yup
        .string()
        .required("Enter a Researchgate link"),
    scopus: Yup
        .string()
        .required("Enter a Scopus link"),
    pub_med: Yup
        .string()
        .required("Enter a Pub Med link"),
    capic_status: Yup
        .string()
        .required("Select one where applicable"),
})

const capicmenu = [
    {"name": "Not Applicable", "value": "NA"},
    {"name": "Intern", "value": "intern"},
    {"name": "Research Assistant", "value": "research assistant"},
    {"name": "Masters (MSc.)", "value": "masters"},
    {"name": "Doctorate (Ph.D)", "value": "doctorate"},
    {"name": "Postdoc", "value": "postdoc"},
    {"name": "Faculty", "value": "faculty"},
    {"name": "Non-Faculty", "value": "non faculty"},
    {"name": "Co-Investigator", "value": "co-investigator"},
    {"name": "Collaborator", "value": "collaborator"},
    {"name": "Research Associate", "value": "research associate"},
    {"name": "Principal Investigator", "value": "principal investigator"},
    {"name": "SAB Member", "value": "sab member"},
    {"name": "ISAB Member", "value": "isab member"}

]


export default function AuthorLinks(props){
    const [redirect, setRedirect] = useState(false)
    const [isDisabled, setDisabled] = useState(true)
    const [show, setShow] = useState(false)
    const [warn, setError] = useState(false)
    const [cookies] = useCookies([]);

    const onClickEdit = () => {
        setDisabled(false)
    }
    const onClickCancel = () => {
        setDisabled(true)
    }
    
    const formik = useFormik({
        initialValues:{
            google_scholar: props.links ? props.links.gs : "" ,
            research_gate: props.links ? props.links.rg : "",
            scopus: props.links ? props.links.sc : "",
            pub_med: props.links ? props.links.pb : "",
            capic_status: props.links ? props.links.ace : ""
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            fetch(process.env.REACT_APP_BACKEND_URL + '/adminPHIS/update-author', {
                credentials: 'include',
                method: 'PATCH',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': cookies['plt']
                },
                body: JSON.stringify(values, null, 2)
            })
            .then(response => response.json())
            .then(function(data){
                if (data.ok){
                    setDisabled(true)
                    setShow(true)
                }
                else{
                    setError(true)
                }
            })
            .catch(function(error){
                setError(true)
            })
        }
    })
    return (
        
         
        <>
        <div className="grid w-full grid-cols-1 gap-8 px-8 py-6 mx-auto rounded-lg  md:px-12 lg:px-16 xl:px-32  dark:text-gray-700">
            <h1 className="text-lg font-medium text-blue-900">Social Links</h1>
            <p className="block -mt-7 text-sm font-medium">Edit your social links</p>
            <form onSubmit={formik.handleSubmit} className="space-y-6 ng-untouched ng-pristine ng-valid">
               
            <div>
                    <TextField
                        fullWidth
                        id="google_scholar"
                        label="Google Scholar"
                        name="google_scholar"
                        type="text"
                        disabled={isDisabled}
                        value={formik.values.google_scholar}
                        onChange={formik.handleChange}
                        error={formik.touched.google_scholar && Boolean(formik.errors.google_scholar)}
                        variant="standard"
                        helperText={formik.touched.google_scholar && formik.errors.google_scholar}
                        InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <SiGooglescholar />
                              </InputAdornment>
                            ),
                          }}
                    />
                </div>
                <div>
                    <TextField
                        fullWidth
                        id="research_gate"
                        label="Research Gate"
                        name="research_gate"
                        type="text"
                        disabled={isDisabled}
                        value={formik.values.research_gate}
                        onChange={formik.handleChange}
                        error={formik.touched.research_gate && Boolean(formik.errors.research_gate)}
                        variant="standard"
                        helperText={formik.touched.research_gate && formik.errors.research_gate}
                        InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <SiResearchgate />
                              </InputAdornment>
                            ),
                          }}
                    />
                </div>
                <div>
                    <TextField
                        fullWidth
                        id="scopus"
                        label="Scopus"
                        name="scopus"
                        type="text"
                        disabled={isDisabled}
                        value={formik.values.scopus}
                        onChange={formik.handleChange}
                        error={formik.touched.scopus && Boolean(formik.errors.scopus)}
                        variant="standard"
                        helperText={formik.touched.scopus && formik.errors.scopus}
                        InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <SiScopus />
                              </InputAdornment>
                            ),
                          }}
                    />
                </div>
                <div>
                    <TextField
                        fullWidth
                        id="pub_med"
                        label="Pub Med"
                        name="pub_med"
                        type="text"
                        disabled={isDisabled}
                        value={formik.values.pub_med}
                        onChange={formik.handleChange}
                        error={formik.touched.pub_med && Boolean(formik.errors.pub_med)}
                        variant="standard"
                        helperText={formik.touched.pub_med && formik.errors.pub_med}
                        InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <SiPubmed />
                              </InputAdornment>
                            ),
                          }}
                    />
                </div>
                <div>
                    <FormControl fullWidth>
                        <InputLabel id="capic_status-label">CApIC-ACE Status</InputLabel>
                        <Select
                            labelId="capic_status-label"
                            id="capic_status"
                            name="capic_status"
                            disabled={isDisabled}
                            value={formik.values.capic_status}
                            label="CApIC-ACE Status"
                            onChange={formik.handleChange}
                        >
                            {capicmenu.map((menu) => (
                                <MenuItem key={menu.value} value={menu.value}>{menu.name}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </div>
                <div className="grid grid-cols-4 gap-4">
                    <button type="submit" disabled={isDisabled} className="w-full p-3 col-span-2 text-sm font-bold tracking-wide uppercase rounded bg-blue-900 text-white disabled:opacity-75">Save</button>
                    <button type="button" disabled={!(isDisabled)} onClick={() => {onClickEdit()}} className="col-span-1 w-full p-3 text-sm font-bold tracking-wide uppercase rounded border-2 border-blue-900 enabled:hover:bg-blue-800 enabled:hover:text-white text-blue-900 disabled:opacity-75">Edit</button>
                    <button type="button" disabled={isDisabled} onClick={() => {onClickCancel()}} className="w-full p-3 text-sm font-bold tracking-wide uppercase rounded border-2 border-blue-900 text-blue-900 enabled:hover:bg-blue-800 enabled:hover:text-white disabled:opacity-75">Cancel</button>
                    
                </div>
                </form>
            
              
                

        </div>
        
        <Snackbar open={show} autoHideDuration={5000} onClose={() => {setShow(false)}}>
            <Alert icon={false} onClose={() => {setShow(false)}} severity="success" sx={{ width: '100%', color: 'white', backgroundColor: '#1e3a8a' }}>
            Submitted
            </Alert>
        </Snackbar>
        <Snackbar open={warn} autoHideDuration={5000} onClose={() => {setError(false)}}>
            <Alert icon={false} onClose={() => {setError(false)}} severity="success" sx={{ width: '100%', color: 'white', backgroundColor: '#ff0000' }}>
            Error
            </Alert>
        </Snackbar>
        </>
        
    )
}