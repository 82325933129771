import { Navigate, Outlet, useLocation } from "react-router-dom";

const ProtectedRoute = ({
    isAllowed,
    fromPath,
    redirectPath = '/auth/signin',
    children,
  }) => {
    let location = useLocation()
    if (!isAllowed && !fromPath) {
      return <Navigate to={redirectPath} state={{ from: location }}  />;
    }
    else if(!isAllowed){
      return <Navigate to={redirectPath} replace  />;
    }
  
    return children ? children : <Outlet />;
  };

export default ProtectedRoute