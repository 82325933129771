import { QueryClient, QueryClientProvider, useQuery } from "@tanstack/react-query";
import { useCookies } from "react-cookie";
import { SiGooglescholar, SiPubmed, SiResearchgate, SiScopus } from 'react-icons/si';
import { Link } from "react-router-dom";
import FollowButton from "./FollowButton";
import Loading from "./Loading";
import MissingError from "./MissingError";

const queryClient = new QueryClient();
export default function AuthorCardLarge(props){
    return (
        <QueryClientProvider client={queryClient}>
            <App id={props.id}/>
        </QueryClientProvider>
    )
}

function App(props){
    const [cookies] = useCookies([])
    let header = {}
    if(!cookies['plt']){
        header  = {}
    }else {
        header = {
            'Content-Type': 'application/json',
            'Authorization': cookies['plt']
        }
    }

    const { isLoading, error, data } = useQuery(['authorData'], () => 
    fetch(process.env.REACT_APP_BACKEND_URL + '/adminPHIS/author/' + props.id, {
        credentials: 'include',
        method: 'GET',
        mode: 'cors',
        headers: header
    }).then(res => 
      res.json()
      ),
      {
        retry: false,
        refetchOnWindowFocus: false
      }
    )
    if (isLoading) return <Loading />
    if (error) return <MissingError />
    return (

        <div class=" rounded-lg w-full bg-gray-50 shadow-md ">
            <div class="flex flex-col items-center justify-center p-4 ">
                <img 
                    src={process.env.REACT_APP_BACKEND_URL + data.data.profile_picture} 
                    alt={`${data.data.firstname}'s profile picture`} 
                    className="w-32 h-32 mx-auto rounded-full dark:bg-gray-500 aspect-square" 
                />
                <p class="text-gray-800  text-xl font-medium mt-2">
                    {data.data.firstname} {data.data.lastname}
                </p>
                <p class="text-blue-900 font-bold text-xs mb-4">
                    {data.data.ace}
                </p>
                <p class="text-xs p-2 text-gray-600 px-4 ">
                    {data.data.about}
                </p>
                <div class="rounded-lg p-2 w-full mt-1">
                    <div class="flex items-center justify-center space-x-4 text-sm text-gray-600 ">
                        <p class="flex flex-col items-center justify-center">
                            Followers
                            <span class="text-blue-900  font-bold">
                                {data.data.number_followers}
                            </span>
                        </p>
                        <p class="flex flex-col items-center justify-center">
                            Following
                            <span class="text-blue-900  font-bold">
                                0
                            </span>
                        </p>
                        
                    </div>
                </div>
                
                <div class="rounded-lg p-2 w-full mt-2">
                    <div class="flex items-center justify-center space-x-4 text-sm text-gray-600 ">
                        
                        
                            <a href={data.data.gs} className="p-2 shadow-md bg-blue-900 hover:bg-blue-800 rounded-full text-white text-xl font-bold">
                                <SiGooglescholar />
                            </a>
                            
                        
                            <a href={data.data.rg} class="p-2 shadow-md bg-blue-900 hover:bg-blue-800 rounded-full text-white text-lg font-bold">
                                <SiResearchgate />
                            </a>
                        
                        
                            <a href={data.data.sc} class="p-2 shadow-md bg-blue-900 hover:bg-blue-800 rounded-full text-white text-lg font-bold">
                                <SiScopus />
                            </a>
                        
                            <a href={data.data.pb} class="p-2 shadow-md bg-blue-900 hover:bg-blue-800 rounded-full text-white text-lg font-bold">
                                <SiPubmed />
                            </a>
            
                    </div>
                </div>
            </div>
        </div>

    )
}