/*
    FileName: BeAuthor.js
    Author: Akande Victor
    href: /dash/author
    Description: This file renders the form for a user to Update their bio.
*/

import { Alert, InputAdornment,  Snackbar, TextField } from "@mui/material";
import { useFormik } from "formik"
import { useState } from "react";
import { useCookies } from "react-cookie"
import { AiOutlineUser } from "react-icons/ai";
import { MdCancel, MdEdit, MdSave } from "react-icons/md";
import * as Yup from 'yup';

const validationSchema = Yup.object({
    bio: Yup
        .string()
        .required('Enter your Bio')
        .min(100, 'Minimum of 100 characters')
        .max(1000, 'Maximum of 1000 characters')   
})


export default function BioForm(props){
    const [redirect, setRedirect] = useState(false)
    const [isDisabled, setDisabled] = useState(true)
    const [show, setShow] = useState(false)
    const [warn, setError] = useState(false)
    const [cookies] = useCookies([]);

    const onClickEdit = () => {
        setDisabled(false)
    }
    const onClickCancel = () => {
        setDisabled(true)
    }
    
    const formik = useFormik({
        initialValues:{
            bio: props.about
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            fetch(process.env.REACT_APP_BACKEND_URL + '/adminPHIS/update.bio', {
                credentials: 'include',
                method: 'PATCH',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': cookies['plt']
                },
                body: JSON.stringify(values, null, 2)
            })
            .then(response => response.json())
            .then(function(data){
                if (data.ok){
                    setDisabled(true)
                    setShow(true)
                }
                else{
                    setError(true)
                }
            })
            .catch(function(error){
                setError(true)
            })
        }
    })
    return (
        
         
        <>
        <div className="grid w-full grid-cols-1 gap-8 px-8 py-8 mx-auto rounded-lg  md:px-12 lg:px-16 xl:px-32  dark:text-gray-700">
            <h1 className="text-lg font-medium text-blue-900">Bio</h1>
            <p className="block -mt-7 text-sm font-medium">Write something short about yourself</p>
            <form onSubmit={formik.handleSubmit} className="space-y-6 ng-untouched ng-pristine ng-valid">
               
                 <div>
                    <TextField
                        fullWidth
                        id="bio"
                        label="Bio"
                        name="bio"
                        type="text"
                        value={formik.values.bio}
                        onChange={formik.handleChange}
                        error={formik.touched.bio && Boolean(formik.errors.bio)}
                        variant="standard"
                        disabled={isDisabled}
                        multiline
                        maxRows={6}
                        helperText={formik.touched.bio && formik.errors.bio}
                        InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <AiOutlineUser />
                              </InputAdornment>
                            ),
                          }}
                    />
                </div>
                <div className="grid grid-cols-4 gap-4">
                    <button type="submit" disabled={isDisabled} className="w-full p-3 col-span-2 text-sm font-bold tracking-wide uppercase rounded bg-blue-900 text-white disabled:opacity-75">Save</button>
                    <button type="button" disabled={!(isDisabled)} onClick={() => {onClickEdit()}} className="col-span-1 w-full p-3 text-sm font-bold tracking-wide uppercase rounded border-2 border-blue-900 enabled:hover:bg-blue-800 enabled:hover:text-white text-blue-900 disabled:opacity-75">Edit</button>
                    <button type="button" disabled={isDisabled} onClick={() => {onClickCancel()}} className="hidden md:block w-full p-3 text-sm font-bold tracking-wide uppercase rounded border-2 border-blue-900 text-blue-900 enabled:hover:bg-blue-800 enabled:hover:text-white disabled:opacity-75">Cancel</button>

                    {/* Mobile View */}
                    <button type="button" disabled={isDisabled} onClick={() => {onClickCancel()}} className="md:hidden w-full p-3  text-2xl font-bold uppercase rounded border-2 border-blue-900 text-blue-900 enabled:hover:bg-blue-800 enabled:hover:text-white disabled:opacity-75"><MdCancel /></button>
                    
                </div>
                </form>
            
              
                

        </div>
        
        <Snackbar open={show} autoHideDuration={5000} onClose={() => {setShow(false)}}>
            <Alert icon={false} onClose={() => {setShow(false)}} severity="success" sx={{ width: '100%', color: 'white', backgroundColor: '#1e3a8a' }}>
            Submitted
            </Alert>
        </Snackbar>
        <Snackbar open={warn} autoHideDuration={5000} onClose={() => {setError(false)}}>
            <Alert icon={false} onClose={() => {setError(false)}} severity="success" sx={{ width: '100%', color: 'white', backgroundColor: '#ff0000' }}>
            Error
            </Alert>
        </Snackbar>
        </>
        
    )
}