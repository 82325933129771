import { Alert, CircularProgress, Slide, Snackbar } from "@mui/material";
import axios from "axios";
import React from "react";
import { Cookies, withCookies } from "react-cookie";
import { instanceOf } from "prop-types";
import { Navigate } from "react-router-dom";

class ImageUpload extends React.Component {
    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    };

    constructor(props){
        super(props);
        const{ cookies } = props
        this.state = {
            currentFile: undefined,
            imagePreview: undefined,
            message: "",
            placeholder: "No file Selected",
            progress: 0,
            isError: false,
            isSuccess: false,
            cookie: cookies.get('plt') || 'plt'
        };

        
    }
    selectFile = (event) => {
        this.setState({
            currentFile: event.target.files[0],
            imagePreview: URL.createObjectURL(event.target.files[0]),
            placeholder: event.target.files[0].name,
            message: "",
            progress: 0
        });
    }
    TransitionDown(props){
        return <Slide {...props} direction="down" />;
    }


    upload() {
        let formData = new FormData();
    
        formData.append("data", this.state.currentFile);
    
        axios.post(process.env.REACT_APP_BACKEND_URL + "/cdn/profile", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            "Authorization": this.state.cookie
          },
          onUploadProgress: (event) => {
            this.setState({
                progress: Math.round((100 * event.loaded) / event.total),
            })
          },
          withCredentials: true
        }
        ).then((response) => {
            this.setState({
                message: "Image Upload Successful",
                isSuccess: true,
                currentFile: undefined,
                imagePreview: undefined,
                progress: 0,
            })
        }
        ).catch((err) => {
            this.setState({
                isError: true,
                message: "Invalid Image format",
                currentFile: undefined,
                imagePreview: undefined,
                progress: 0,
                placeholder: "No file selected"
            })
        })
    }

    resetError() {
        this.setState({
            isError: false,
            message: "",
            progress: 0
        })
    }
    resetSuccess() {
        this.setState({
            isSuccess: false,
            message: "",
            progress: 0
        })
    }
    render () {
        let { currentFile, imagePreview, isError, message, isSuccess, progress } = this.state
        let numberOfBytes = 0
        const MAX_SIZE = 5000000 / 1024
        if (currentFile){
            numberOfBytes = currentFile.size
            const approx = numberOfBytes / 1024 ;
            console.log(approx)
            if (approx > MAX_SIZE){
                this.setState({
                    isError: true,
                    message: "File too large.",
                    currentFile: undefined,
                    imagePreview: undefined
                })
            }
        }
        if (this.state.cookie === "plt"){
            return <Navigate to={'/auth/signin'} />
        }

        return (
            <>
            {isError &&
            <Snackbar 
                TransitionComponent={this.TransitionDown}
                anchorOrigin={{ horizontal: 'center', vertical: 'top' }} 
                open={isError} autoHideDuration={5000} onClose={() => {this.resetError()}}>
                <Alert onClose={() => {this.resetError()}} severity="error" sx={{ width: '100%', color:"white", backgroundColor:"rgb(127 29 29)" }}>
                {message}
                </Alert>
            </Snackbar>}

            {isSuccess &&
            <Snackbar 
                TransitionComponent={this.TransitionDown}
                anchorOrigin={{ horizontal: 'center', vertical: 'top' }} 
                open={isSuccess} autoHideDuration={5000} onClose={() => {this.resetSuccess()}}>
                <Alert onClose={() => {this.resetSuccess()}} severity="success" sx={{ width: '100%', color:"white", backgroundColor:"#1e3a8a" }}>
                {message}
                </Alert>
            </Snackbar>}
            
            <fieldset className="w-full space-y-1 text-gray-800 mx-auto md:px-12 lg:px-16 xl:px-32">
                <h1 className="text-lg font-medium text-blue-900">Profile Picture</h1>
                <p className="block text-sm font-medium">Change your profile picture. Upload an image from your computer.</p>
                <p className="block text-sm font-thin"><em>Max size 5mb. formats: jpg, jpeg, png</em></p>
                {imagePreview && (
                    <div>
                        <img className="w-32 h-32 mx-auto rounded-full ring-2 ring-blue-900 ring-offset-2 dark:bg-gray-500 aspect-square" src={imagePreview} alt="" />
                    </div>
                )}
                {currentFile ? <p className="block text-sm font-medium">{currentFile.name}</p> : null}
                {progress > 0 && progress < 100 ? 
                <>
                <div className="grid grid-cols-4 gap-4">
                <CircularProgress variant="determinant" value={progress} />
                 <p>{progress}% uploading</p>
                 </div>
                 </> : null}
                 {progress === 100 ? "Done Uploading" : null}
                
                    <input 
                        id="btn-image" 
                        type="file" 
                        name="btn-image" 
                        accept="image/*"
                        style={{ display: 'none' }}
                        placeholder={this.state.placeholder}
                        onChange={this.selectFile}
                        className="w-full px-8 py-12  border-2 border-dashed rounded-md border-blue-200 text-gray-800 bg-gray-50" 
                        />
                <div className="grid grid-cols-4 gap-4">
                <label 
                    for="btn-image" 
                    htmlFor="btn-image"
                    className="col-span-2 p-3 text-sm text-center font-bold tracking-wide uppercase rounded bg-blue-900 text-white disabled:opacity-75 cursor-pointer"
                    >
                Choose File
                </label>
                <button
                    disabled={!currentFile} 
                    onClick={() => {this.upload()}}
                    className="col-span-2 w-full p-3 text-sm font-bold tracking-wide uppercase rounded border-2 border-blue-900 enabled:hover:bg-blue-800 enabled:hover:text-white text-blue-900 disabled:opacity-75 disabled:cursor-not-allowed">Upload
                    </button>
                </div>
            </fieldset>
            </>
        )
    }
}

export default withCookies(ImageUpload)