import { BsPencil } from "react-icons/bs";
import { Link } from "react-router-dom";

function EditButton(props){
    return (
        <Link to={'/phis/edit-post/' + props.slug} className="flex items-center p-1 space-x-1.5 ">
            <BsPencil className="text-blue-700 hover:text-blue-900" />
        </Link> 
    )
}

export default EditButton