import { QueryClient, QueryClientProvider, useQuery } from "@tanstack/react-query";
import { useCookies } from "react-cookie";
import { SiGooglescholar, SiPubmed, SiResearchgate, SiScopus } from 'react-icons/si';
import { Link } from "react-router-dom";
import FollowButton from "./FollowButton";
import Loading from "./Loading";
import MissingError from "./MissingError";

const queryClient = new QueryClient();
export default function ProfileCard(props){
    return (
        <QueryClientProvider client={queryClient}>
            <App id={props.id}/>
        </QueryClientProvider>
    )
}

function App(props){
    const [cookies] = useCookies([])

    const { isLoading, error, data } = useQuery(['authorData'], () => 
    fetch(process.env.REACT_APP_BACKEND_URL + '/adminPHIS/users', {
        credentials: 'include',
        method: 'GET',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json', 
            'Authorization': cookies['plt']
        }
    }).then(res => 
      res.json()
      ),
      {
        retry: false,
        refetchOnWindowFocus: true
      }
    )
    if (isLoading) return <Loading />
    if (error) return <MissingError />
    return (

        <div class=" rounded-lg w-full bg-gray-50 shadow-md ">
            <div class="flex flex-col items-center justify-center p-4 ">

	            <img 
                    src={process.env.REACT_APP_BACKEND_URL + data.user.profile_picture} 
                    alt={`${data.user.firstname}'s profile picture`} 
                    className="w-32 h-32 mx-auto rounded-full dark:bg-gray-500 aspect-square" 
                />
                
                <p class="text-gray-800  text-xl font-medium mt-2">
                    {data.user.firstname} {data.user.lastname}
                </p>
                {data.author && <p class="text-blue-900 font-bold text-sm mb-4">
                    {data.application.ace}
                </p>}
                {!data.author && <p class="bg-blue-900 p-1 text-white rounded-full text-sm mb-4">
                    {data.user.role === "P" ? "Public User" : "Admin"}
                </p>}
                {data.user.about !== "" &&
                <p class="text-xs p-2 text-gray-600 text-center max-w-screen-md px-4 ">
                    {data.user.about}
                </p>}
                {data.user.about === "" &&
                <p class="text-xs p-2 text-gray-600 px-4 text-center font-thin font-serif ">
                    No Bio found. Tell people something about yourself. <br /> Click on the Settings Tab.
                </p>}
                
                {data.author && 
                <>
                <div class="rounded-lg p-2 w-full mt-1">
                    <div class="flex items-center justify-center space-x-4 text-sm text-gray-600 ">
                        <p class="flex flex-col items-center justify-center">
                            Followers
                            <span class="text-blue-900  font-bold">
                                {data.application.number_followers}
                            </span>
                        </p>
                        <p class="flex flex-col items-center justify-center">
                            Following
                            <span class="text-blue-900  font-bold">
                                0
                            </span>
                        </p>
                        
                    </div>
                </div>
                <div class="rounded-lg p-2 w-full mt-2">
                    <div class="flex items-center justify-center space-x-4 text-sm text-gray-600 ">
                        
                        
                            <a href={data.application.gs} className="p-2 shadow-md bg-blue-900 hover:bg-blue-800 rounded-full text-white text-xl font-bold">
                                <SiGooglescholar />
                            </a>
                            
                        
                            <a href={data.application.rg} class="p-2 shadow-md bg-blue-900 hover:bg-blue-800 rounded-full text-white text-lg font-bold">
                                <SiResearchgate />
                            </a>
                        
                        
                            <a href={data.application.sc} class="p-2 shadow-md bg-blue-900 hover:bg-blue-800 rounded-full text-white text-lg font-bold">
                                <SiScopus />
                            </a>
                        
                            <a href={data.application.pb} class="p-2 shadow-md bg-blue-900 hover:bg-blue-800 rounded-full text-white text-lg font-bold">
                                <SiPubmed />
                            </a>
            
                    </div>
                </div>
                </>}
            </div>
        </div>

    )
}
