import { TbServerOff } from 'react-icons/tb'
export default function MissingError() {
    return (
        <section className="flex items-center  sm:p-16 text-gray-800">
        <div className="container flex flex-col items-center justify-center px-5 mx-auto my-8 space-y-8 text-center sm:max-w-md">
            <TbServerOff className="w-20 h-20 text-blue-900" />
             
            <p className="text-3xl">Server is down</p>
            <p className="text-3xl">Resource Not Found</p>
        </div>
    </section>
    )
}