import { AiOutlineEye, AiOutlineHeart } from "react-icons/ai"
import { BsBookmark } from "react-icons/bs"
import ReactMarkdown from "react-markdown"
import { Link } from "react-router-dom"
import EditButton from "./EditButton"
import WithdrawButton from "./WithdrawButton"

export default function Card(props){
    const { data, user_id } = props
    return(
        <div className="flex flex-col max-w-full w-full p-6 space-y-2 overflow-hidden border-2 border-gray-100">
            <div className="flex space-x-4">
                <div className="flex flex-row text-green-600 justify-center items-center space-x-1">
                    <Link to={'/phis/author/' + data.author_id} preventScrollReset={false} className="text-sm font-semibold">{data.first_name} {data.last_name}</Link>
                    <span className="text-xs">{data.read_time} mins read</span>
                </div>
            </div>
            <div>
                <Link to={'/phis/read/' + data.slug} preventScrollReset={false} ><h2 className="mb-1 text-xl text-blue-700 font-semibold">{data.title}</h2></Link>
                <p className="text-sm text-black"><ReactMarkdown children={data.body.slice(0, 150) + "..."} /></p>
            </div>
            <div className="flex flex-wrap justify-between text-blue-700">
                <div className="flex space-x-2">
                   {user_id === data.author_id &&
                   <>
                    <div>
                        <WithdrawButton post_id={data._id['$oid']} title={data.title} filter="author" />
                        </div>
                    <div>
                        <EditButton slug={data.slug} />
                        </div>
                    </>
                   }
                   
                </div>
                <div className="flex space-x-2 text-sm ">
                    <p type="button" className="flex items-center p-1 space-x-1.5 text-blue-700 hover:text-blue-900">
                        <AiOutlineEye />
                        <span>{data.views}</span>
                    </p>
                    <p type="button" className="flex items-center p-1 space-x-1.5 text-blue-700 hover:text-blue-900">
                        <AiOutlineHeart />
                        <span>{data.likes}</span>
                    </p>
                </div>
            </div>
        </div>
    )
}