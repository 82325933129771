import * as React from 'react';
import { LinearProgress, linearProgressClasses, Stack, styled } from "@mui/material";

function Loading(){
    const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
        height: 5,
        borderRadius: 5,
        [`&.${linearProgressClasses.colorPrimary}`]: {
          backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
        },
        [`& .${linearProgressClasses.bar}`]: {
          borderRadius: 5,
          backgroundColor: theme.palette.mode === 'light' ? 'rgb(30 58 138)' : 'rgb(191 219 254)',
        },
      }));

    const [progress, setProgress] = React.useState(0);

    React.useEffect(() => {
        const timer = setInterval(() => {
          setProgress((oldProgress) => {
            if (oldProgress === 100) {
              return 0;
            }
            const diff = Math.random() * 10;
            return Math.min(oldProgress + diff, 100);
          });
        }, 500);
    
        return () => {
          clearInterval(timer);
        };
      }, []);
    
    
    return (
        <>    
        <Stack  sx={{ width: '100%' }}>
            <BorderLinearProgress variant='determinate' value={progress}  />
        </Stack>
        </>
    )
}

export default Loading