import { QueryClient, QueryClientProvider, useQuery } from "@tanstack/react-query";
import { useCookies } from "react-cookie";
import { SiGooglescholar, SiPubmed, SiResearchgate, SiScopus } from 'react-icons/si';
import { Link } from "react-router-dom";
import Loading from "../../../../components/elements/Loading";
import MissingError from "../../../../components/elements/MissingError";
import ImageUpload from "../../../../components/FileUpload/ImageUpload";
import AuthorLinks from "../../../../components/Forms/AuthorLinks";
import BioForm from "../../../../components/Forms/BioForm";

const queryClient = new QueryClient();
export default function ProfileSettings(props){
    return (
        <QueryClientProvider client={queryClient}>
            <App id={props.id}/>
        </QueryClientProvider>
    )
}

function App(props){
    const [cookies] = useCookies([])

    const { isLoading, error, data } = useQuery(['authorData'], () => 
    fetch(process.env.REACT_APP_BACKEND_URL + '/adminPHIS/users', {
        credentials: 'include',
        method: 'GET',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json', 
            'Authorization': cookies['plt']
        }
    }).then(res => 
      res.json()
      ),
      {
        retry: false,
        refetchOnWindowFocus: false
      }
    )
    if (isLoading) return <Loading />
    if (error) return <MissingError />
    return (

        <div class=" rounded-lg max-w-screen-lg w-full divide-gray-200 mt-6 ">
            <div class="flex flex-col items-center justify-center p-4 ">
                <ImageUpload />
                <BioForm about={data.user.about} />
               
                
                {data.author &&
                    <AuthorLinks links={data.application ? data.application : null} /> 
                }
            </div>
        </div>

    )
}